import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/packages/gatsby-theme-fuegodocs/src/components/Layout/Layout.js";
import { Code } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
import { Component } from "react";
import { InlineCode } from "@fuegokit/gatsby-theme-fuegodocs/src/components/Code";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const ArticlePageWrapper = makeShortcode("ArticlePageWrapper");
const BackButton = makeShortcode("BackButton");
const Heading = makeShortcode("Heading");
const Text = makeShortcode("Text");
const DoDontChecklist = makeShortcode("DoDontChecklist");
const DoDontChecklistHeading = makeShortcode("DoDontChecklistHeading");
const DoDontChecklistItem = makeShortcode("DoDontChecklistItem");
const ImageWrapper = makeShortcode("ImageWrapper");
const ImageCaption = makeShortcode("ImageCaption");
const Link = makeShortcode("Link");
const VercelAnalytics = makeShortcode("VercelAnalytics");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ArticlePageWrapper mdxType="ArticlePageWrapper">
      <BackButton mdxType="BackButton" />
      <Heading as="h1" mdxType="Heading">Empty states</Heading>
      <Text mdxType="Text">
When crafted intentionally with best practices in mind, empty states encourage engagement, improve learnability, and deliver essential feedback.
      </Text>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Identify your use case</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When designing an empty state, start by understanding why there's nothing to display.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Someone might have just installed the app and not interacted with it yet.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    A feature—new or existing—may require an action from the user side.
  </DoDontChecklistItem>
    <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    There could be nothing to display and no action to take.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    The search wasn't successful, or nothing matched the filters.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    There might be no connection, the page wasn't found, or the access is limited.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">App or feature first use</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Heading tip: think of what there will be instead of what's missing.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use the description to educate, motivate, and guide people to the next steps.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When someone starts using an app, it's an organic opportunity to foster interaction.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Unlike guided tours, empty states appear in context and don't interrupt user flow.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    You can use them to reduce time to value and improve the system's learnability.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
   Don't repeat your heading in the description. That's wasted space.
  </DoDontChecklistItem>
    <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
   Don't try to cover everything. Aim to limit your description to 2 lines.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/e017596e-d2a1-4e89-8ca8-4ca70a564785" alt="An example of an empty state for the first time someone uses a feature or app." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">Saying <i>Add your first [what]</i> is an easy yet effective way to encourage action.
The description outlines the benefits, and the call to action clearly matches the heading.</ImageCaption><br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/390eba34-655e-49bf-9ea7-95e7d2e18711" alt="An example of an empty state for the first time someone uses a feature or app." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">To help people grasp the value right away, you might want to bring it to the heading. 
This clarifies the purpose and enhances engagement.</ImageCaption><br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/b34d3e6b-24a9-49d2-bea1-9dd46baa0d22" alt="An example of an empty state for the first time someone uses a feature or app." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">This heading clearly outlines the action to take, allowing the description to address the system's temporary constraints and improve the feature's learnability.</ImageCaption><br></br>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">There's nothing here, but there will be</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Sometimes there's nothing to display and viewers can't take any action.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Keep headings informative and emphasize that the state they describe is temporary.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Use the description to educate people further or highlight the benefit.
  </DoDontChecklistItem>
    <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
   Don't add external links just to fill space. Include them only if they bring value.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/111f55c7-8a99-4b56-bbc9-6e1ff53cd906" alt="An example of an empty state that indicates the user needs to wait for an action to complete before content appears." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">Adding <i>yet</i> at the end of the heading indicates a temporary state. 
The description explains what needs to happen first, and the link offers relevant resources.</ImageCaption><br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/81a4090a-6f01-4be8-a7ba-8f53e47711af" alt="An example of an empty state that indicates the user needs to wait for an action to complete before content appears." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">This heading focuses on the feature's value, while the description educates first-time users about what to expect and how to leverage it effectively.</ImageCaption><br></br>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">No results found</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    When the search or filters return no results, provide immediate, clear feedback.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Start by clearly and concisely communicating the outcome.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Then, offer a helpful tip, such as trying a different keyword or adjusting the filters.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Sounds too general? Consider other options, like pointing to advanced search.
  </DoDontChecklistItem>
    <DoDontChecklistItem appearance="danger" mdxType="DoDontChecklistItem">
   Those empty states are highly contextual and often appear inline. Don't overlook them.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/4f0ee406-5d7d-4abd-9ff5-68984e3cdaf6" alt="An example of an empty state is when no tasks match the filters." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">This type of empty state is responsible for providing feedback to the user. 
Ensure they understand what happened and provide helpful tips.</ImageCaption><br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/c2425b85-fe47-455a-b675-6da42a5293d9" alt="An example of an empty state when the search returns no results." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">An empty state doesn't always have to take up the entire screen. 
Contextual help makes it easier to apply feedback and remember how the system works.</ImageCaption><br></br>
      <hr />
      <DoDontChecklist mdxType="DoDontChecklist">
  <DoDontChecklistHeading mdxType="DoDontChecklistHeading">Errors that appear as empty states</DoDontChecklistHeading>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    It might happen that things don't go as expected, and people can't access the app.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    These issues often relate to a lack of connection, permissions, or removed content.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    Design those empty states to offer guidance and provide a way out.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
    For the heading, summarize what happened—and never blame the user.<br></br>
    „Insufficient permissions” or „We couldn't find this page” will work.
  </DoDontChecklistItem>
    <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
   When the user needs to take action, aim to start with it. For example:<br></br>
   „Enable your cookies,” „Upgrade to the new version,” or „Check your connection.”
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
   Use the description to explain any necessary details or next steps.
  </DoDontChecklistItem>
  <DoDontChecklistItem appearance="success" mdxType="DoDontChecklistItem">
   You might want to add some warmth if the problem is on the system's side.
  </DoDontChecklistItem>
      </DoDontChecklist>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/4a24168d-f5c6-4499-be33-dc0c2ee9557b" alt="An example of an empty state when the user lacks permission." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">The heading doesn't refer to the user directly, making it friendlier. 
Including a helpful feature like a permission helper enhances the overall usability.</ImageCaption><br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/39d43af8-45be-48fd-9995-7c657aa8967c" alt="An example of an empty state when the user has cookies disabled." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">When people quickly scan this empty state, they immediately know what to do.<br></br>
They can also easily find additional information if needed.</ImageCaption><br></br>
      <ImageWrapper mdxType="ImageWrapper">
  <img width="290" src="https://github.com/user-attachments/assets/6e05c53d-dfe8-40df-8a0f-3cab7eb2c0ee" alt="An example of an empty state when the connection couldn't be established." />
      </ImageWrapper>
      <ImageCaption mdxType="ImageCaption">This empty state takes responsibility for the system issue, which helps build trust.<br></br>
A few extra words of explanation reassure people that it's not their fault.</ImageCaption><br></br>
      <DoDontChecklist mdxType="DoDontChecklist">
    Discover more tips on creating{" "}
    <Link href={`https://www.fuegokit.design/content/error-messages`} mdxType="Link">
      user-friendly error messages.
    </Link>{" "}
      </DoDontChecklist>
      <hr />
      <p>{`Share your feedback on the #design-systems channel in Slack.`}</p>
    </ArticlePageWrapper>
    <VercelAnalytics mdxType="VercelAnalytics" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      